import {Controller} from "stimulus"

export default class extends Controller {
  static targets = [ "price", "cost"]

  connect() {
  }

  suggested_price(){
    var cost = this.costTarget.value;
    var price = (+(cost * 1.2) + +cost).toFixed(2);
    this.priceTarget.value = price
  }

}
