import bsCustomFileInput from 'bs-custom-file-input';

$(document).on('ready turbolinks:load', function() {
  bsCustomFileInput.init();
})

document.addEventListener("turbolinks:load", () => {

   $("#user_phone").mask("(999) 999-9999");

   $('[data-toggle="tooltip"]').tooltip()
   $('[data-toggle="popover"]').popover()

  $( "#show-search-form" ).click(function( event ) {
      event.preventDefault();
     $('.search-form').show();
  });

  // Plain jquery
   $('#fadeMe').fadeOut(5000);

   // jquery-ui
   const availableCities = ['Baltimore', 'New York'];
   $('#cityField').autocomplete( { source: availableCities } );
   $('#calendarField').datepicker( { dateFormat: 'yy-mm-dd' } );
   // $('#order_item_estimated_delivery_date').datepicker( { dateFormat: 'yy-mm-dd' } );


   // $(".alert").delay(5000).slideUp(500, function(){
   //      $(".alert").alert('close');
   //  });



})
