import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field", "characterCount" ]

  initialize() {
     this.length()
   }

   length() {
     var count = this.fieldTarget.value.length
     if (count == 1) {
       var message = "1 character"
     } else {
       var message = `${count} characters`
     }

     this.characterCountTarget.textContent = message

     if (count > 159) {
       this.characterCountTarget.classList.add("text-danger")
     } else {
       this.characterCountTarget.classList.remove("text-danger")
     }
   }

   clear(event) {
     event.preventDefault()
     this.fieldTarget.value = " "
     // var message = "hi"
     // this.fieldTarget.textContent = message
   }

   submit(event) {
     var count = this.fieldTarget.value.length
     if (count > 159) {
       event.preventDefault()
     }
   }

}
