import ApplicationController from './application_controller'
import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static targets = [ "calculatedTotal", "quantity", "price"]

  connect() {
    StimulusReflex.register(this)
  }

  get quantity() {
    return this.targets.find('quantity')
  }

  get price() {
    return this.targets.find('price')
  }


  recalculate_price(event){
        const id = event.target.dataset.id
        var rowId = "order_item_" + id
        var itemRow = document.getElementById(rowId)
        const resultsArea = itemRow.querySelector('.calculated-results')
        const priceEach = itemRow.querySelector('.get-price')
        var quantity = this.quantity.value
        const priceLevels = JSON.parse(this.quantityTarget.dataset.info) // data object
        var total = 0
        if (quantity < priceLevels[0]["min_qty"]) {
          resultsArea.innerHTML = "Minimum order is " + priceLevels[0]["min_qty"]
        } else {
          var count = Object.keys(priceLevels).length;

          for (var i = 0; i < count; i++) {
            if ((quantity >= priceLevels[i]["min_qty"]) && (quantity <= priceLevels[i]["max_qty"])) {
              priceEach.innerHTML = priceLevels[i]["price"]
              total = calculatePrice(priceLevels[i]["price"], quantity)
              resultsArea.innerHTML = "$" + total
              this.stimulate("CartItemReflex#change_qty", event.target, quantity);
            }
          }
       }
    }


  // update(event) {
  //     event.preventDefault()
  //     const id = event.target.dataset.id
  //     const url = event.target.dataset.url
  //     var quantity = this.quantity.value
  //     let data
  //     data = `cart_item[quantity]=${quantity}`
  //
  //     Rails.ajax({
  //       url: url,
  //       type: 'patch',
  //       data: data
  //     })
  // }


}

function calculatePrice(price, quantity) {
  var total = price * quantity
  total = formatMoney(total)
  return total
}



function formatMoney(number, decPlaces, decSep, thouSep) {
  decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
  decSep = typeof decSep === "undefined" ? "." : decSep;
  thouSep = typeof thouSep === "undefined" ? "," : thouSep;
  var sign = number < 0 ? "-" : "";
  var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
  var j = (j = i.length) > 3 ? j % 3 : 0;

  return sign +
	 (j ? i.substr(0, j) + thouSep : "") +
	  i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
	   (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
}
