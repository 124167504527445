// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.Rails = require("@rails/ujs")
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
import { Application } from "@hotwired/stimulus"
import { Autocomplete } from "stimulus-autocomplete/src/autocomplete.js"
const application = Application.start()
require('jquery')
require("jquery-ui")
// require("packs/vendor/slick")
require("packs/vendor/jquery-maskedinput")
import "controllers"
import 'bootstrap'
require("packs/vendor/main")
require("packs/custom")
import './src/application.scss'
import "../stylesheets/application"

require.context('../images/', true, /\.(gif|jpg|png|svg|eot|ttf|woff|woff2)$/i)

// Configure Stimulus development experience
application.warnings = true
application.debug    = false
window.Stimulus      = application

window.Stimulus.register("autocomplete", Autocomplete)

export { application }

Rails.start()


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
