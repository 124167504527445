import ApplicationController from './application_controller'
import { Controller } from 'stimulus'

export default class extends ApplicationController {
  static targets = ['input']

  cancelEdit (event) {
    if (event.type === 'keyup' && !['Escape', 'Esc'].includes(event.key)) return
    this.stimulate('ProductColorsReflex#cancel_edit')
  }

  afterEdit (element) {
   const input = element.querySelector('input[type="text"]')
   const value = input.value
   input.focus()
   input.value = ''
   input.value = value
 }

}
