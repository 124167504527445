import {Controller} from "stimulus"

export default class extends Controller {
  static get targets() {
    return ["image", "picker"]  
  }

  initialize() {
    this.showImage("0")
  }

  showImage(id) {
    for (let image of this.imageTargets) {
      if (image.dataset.id === id) {
        image.style.display = 'block'
      } else {
        image.style.display = 'none'
      }
    }
  }

  // showCurrentGallery(event) {
  //   for (let image of this.imageTargets) {
  //     image.style.display = this.isCurrentGallery(image) ? 'block' : 'none'
  //   }
  // }

  // currentImage(id) {
  //   return id
  // }
  
  // isCurrentGallery(image) {
  //   return image.dataset.id === this.pickerTarget.value
  // }

  isCurrentImage(image) {
    return image.dataset.id === id
  }

  currentId() {
    return id
  }

  colorSelectedByLink(e) {
    e.preventDefault()
    const id = e.currentTarget.getAttribute('id')
    this.showImage(id)
    // const product_color_id = event.target.value 
    // console.log(event.target.src)
  }

  colorSelectedByDropdown(e) {
    const id = e.target.value 
    this.showImage(id)
  }

}