// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
import controller from './application_controller'

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

StimulusReflex.initialize(application, { consumer })
if (process.env.RAILS_ENV === 'development') StimulusReflex.debug = true


document.addEventListener("turbolinks:load", () => {

  //Correct display of active and open arrows.
  $('.navbar .dropdown-menu').on('show.bs.collapse  hidden.bs.collapse', function (event) {
    event.stopPropagation();
    (event.type=='show')?$(this).parent().addClass('show'):$(this).parent().removeClass('show');
  });

  //Close a menu by clicking anywhere on the document.
  $(document).on('click', function (e) {
    if (!$(e.target).closest('.dropdown-menu').length)  {
      $('.navbar .dropdown-menu.collapse.show, .navbar .dropdown-menu.collapsing').collapse('hide');
    }
  });

  //Close all submenus if the parent menu is closed.
  $('.navbar .dropdown-menu').on('hidden.bs.collapse', function (event) {
    event.stopPropagation();
    $(this).find('.collapse.show').each(function () {$(this).collapse('hide');});
  });

  //Close a menu by clicking on other menu item.
  $('.navbar .dropdown-menu').on('show.bs.collapse shown.bs.collapse', function (event) {
    event.stopPropagation();
    $(this).parent().siblings('.show').children('.dropdown-menu').collapse('hide');
  });

  //How to fix MegaNavbar menu when scrolling page
  // var target=$('.navbar'), offset = target.offset().top;
  // $(window).scroll(function() {
  //   if (!$('.navbar').is('.fixed-onscroll')) return false;
  //   if ($(window).scrollTop() >= offset) {target.addClass('fixed-top'); $('body').css({'margin-top': target.height()+'px'});}
  //   else {target.removeClass('fixed-top'); $('body').css({'margin-top': ''});}
  // });


  //Open menu on hover.
  //Check if not collapsed
  var CheckHover = function(e) {
    if (!$('.navbar').is('.navbar-hover')) return false;
    var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return (e.is('.navbar-expand')) ||
      (e.is('.navbar-expand-xl') && w >= 1200) || (e.is('.navbar-expand-lg') && w >= 992) ||
      (e.is('.navbar-expand-md') && w >= 768) ||  (e.is('.navbar-expand-sm') && w >= 576);
  }

  //Prevent click if hover
  $('.navbar [data-toggle="collapse"]').on( "click", function(e) {if (CheckHover($(this.closest('.navbar')))) {e.preventDefault(); e.stopPropagation();}});

  $('.navbar [class*="drop"]').hover(
    function() {
      if (!CheckHover($(this.closest('.navbar')))) return;
      $(this).children('.dropdown-menu').collapse('show').on('shown.bs.collapse.hover', function (e) {
        if (!$(this).parent(':hover').length) {$(this).collapse('hide').parent().removeClass('show');}
        $(this).off('shown.bs.collapse.hover');
      }).parent().addClass('show');
    },
    function() {
      if (!CheckHover($(this.closest('.navbar')))) return;
      $(this).children('.dropdown-menu').collapse('hide').on('hidden.bs.collapse.hover', function (e) {
        if ($(this).parent(':hover').length) {$(this).collapse('show').parent().addClass('show');}
        $(this).off('hidden.bs.collapse.hover');
      }).parent().removeClass('show');
    }
  );

  // $("[data-trigger]").on("click", function(e){
  //       e.preventDefault();
  //       e.stopPropagation();
  //       var offcanvas_id =  $(this).attr('data-trigger');
  //       $(offcanvas_id).toggleClass("show");
  //       $('body').toggleClass("offcanvas-active");
  //       $(".screen-overlay").toggleClass("show");
  //   });
  //
  //   // Close menu when pressing ESC
  //   $(document).on('keydown', function(event) {
  //       if(event.keyCode === 27) {
  //           $(".screen-overlay").removeClass("show");
  //          $(".mobile-offcanvas").removeClass("show");
  //          $("body").removeClass("offcanvas-active");
  //
  //       }
  //   });
  //
  //   $(".btn-close, .screen-overlay").click(function(e){
  //       $(".screen-overlay").removeClass("show");
  //       $(".mobile-offcanvas").removeClass("show");
  //       $("body").removeClass("offcanvas-active");
  //   });
  //
  //   $('.multi-level-dropdown .dropdown-submenu > a').on("mouseenter", function(e) {
  //   var submenu = $(this);
  //   $('.multi-level-dropdown .dropdown-submenu .dropdown-menu').removeClass('show');
  //   submenu.next('.dropdown-menu').addClass('show');
  //   e.stopPropagation();
  // });
  //
  // $('.multi-level-dropdown .dropdown').on("hidden.bs.dropdown", function() {
  //   // hide any open menus when parent closes
  //   $('.multi-level-dropdown .dropdown-menu.show').removeClass('show');
  // });
// Bootstrap Menu
  // const classChildren = x => document.getElementsByClassName(`navbar-${x}`)[0];
  //
  // classChildren('toggler').addEventListener('click', () => {
  //   classChildren('collapse').classList.toggle('collapse');
  // }, false);

  // var navbarToggler = document.getElementById("navbar-toggler");
  // navbarToggler.addEventListener('click', () => {
    // classChildren('collapse').classList.toggle('collapse');
    // alert('NavBar Activated')
  // }, false);

})
