import {Controller} from "stimulus"
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static targets = [ "calculatedTotal", "subtotal", "item_total"]

  connect() {
  }

  totalitup() {
    const subtotalResults = document.querySelector('.calculated-subtotal')
    const discountValue = document.querySelector('.calculated-discount')
    const totalResult = document.querySelector('.calculated-total')
    var arr = [];
    this.calculatedTotalTargets.forEach(x => {
      if (!x.textContent.includes("Minimum order is")) {
        var n = Number(x.textContent.replace(/[^0-9.-]+/g,""))
        arr.push(n)
      }
    })

    if (this.calculatedTotalTargets.length == arr.length) {
      var sum = arr.reduce((a, b) => a + b, 0)
      sum -= discountValue;
      var newTotal = "$" + formatMoney(sum)
      subtotalResults.innerHTML = newTotal
      totalResult.innerHTML = newTotal
    }

  }

}

function calculatePrice(price, quantity) {
  var total = price * quantity
  total = formatMoney(total)
  return total
}

function formatMoney(number, decPlaces, decSep, thouSep) {
  decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
  decSep = typeof decSep === "undefined" ? "." : decSep;
  thouSep = typeof thouSep === "undefined" ? "," : thouSep;
  var sign = number < 0 ? "-" : "";
  var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
  var j = (j = i.length) > 3 ? j % 3 : 0;

  return sign +
	 (j ? i.substr(0, j) + thouSep : "") +
	  i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
	   (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
}
