import {Controller} from "stimulus"
import { ajax } from '@rails/ujs';

export default class extends Controller {
  static targets = [ "calculatedTotal", "quantity", "price"]

  connect() {
  }

  decreaseQty(event) {
    event.preventDefault()
    const resultsArea = document.querySelector('.calculated-results')

    const lastValue = +this.quantity.value
    let total = 0
    if (lastValue > 50) {
      this.quantity.value = +this.quantity.value - 50
    } else {
      this.quantity.value = 0
    }
    total = calculatePrice(price, this.quantity.value)
    resultsArea.innerHTML = "$" + total
  }

  increaseQty(event) {
    event.preventDefault()
    const resultsArea = document.querySelector('.calculated-results')

    const lastValue = +this.quantity.value
    let total = 0
    this.quantity.value = +this.quantity.value + 50
    total = calculatePrice(price, this.quantity.value)
    resultsArea.innerHTML = "$" + total
  }

  get quantity() {
    return this.targets.find('quantity')
  }

  get price() {
    return this.targets.find('price')
  }

  recalculate_price(){
        const resultsArea = document.querySelector('.calculated-results')
        const priceEach = document.querySelector('.get-price')
        var quantity = this.quantity.value
        const priceLevels = JSON.parse(this.quantityTarget.dataset.info) // data object
        var total = 0
        if (quantity < priceLevels[0]["min_qty"]) {
          resultsArea.innerHTML = "Minimum order is " + priceLevels[0]["min_qty"]
        } else {
          var count = Object.keys(priceLevels).length;

          for (var i = 0; i < count; i++) {
            if ((quantity >= priceLevels[i]["min_qty"]) && (quantity <= priceLevels[i]["max_qty"])) {
              priceEach.innerHTML = priceLevels[i]["price"]
              total = calculatePrice(priceLevels[i]["price"], quantity)
              resultsArea.innerHTML = "$" + total
            }
          }
        }
  }

}

function calculatePrice(price, quantity) {
  var total = price * quantity
  total = formatMoney(total)
  return total
}



function formatMoney(number, decPlaces, decSep, thouSep) {
  decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
  decSep = typeof decSep === "undefined" ? "." : decSep;
  thouSep = typeof thouSep === "undefined" ? "," : thouSep;
  var sign = number < 0 ? "-" : "";
  var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
  var j = (j = i.length) > 3 ? j % 3 : 0;

  return sign +
	 (j ? i.substr(0, j) + thouSep : "") +
	  i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
	   (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
}
