import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
  static targets = [ "productsTable", "defaultCategory"]

  connect() {
    StimulusReflex.register(this)
  }

  categoryClick(e) {
    if (e.target.tagName == 'LI') {
      let check = e.target.getElementsByTagName('INPUT')[0]
      check.checked = !check.checked
      this.updateProducts()
    }
  }

  updateProducts() {
    const values = Array
      .from(document.querySelectorAll('input[type="checkbox"]:checked'))
      .map((checkbox) => checkbox.value);

    let catIdsParam;
    if (values.length === 0) {
      catIdsParam = '?categories=' + this.defaultCategoryTarget.innerText
    } else {
      catIdsParam = '?categories=' + values.join(',')
    }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    fetch(catIdsParam, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrfToken
      }
    })
      .then(response => response.text())
      .then(html => {
        this.productsTableTarget.innerHTML = html
      })
  }

  ounceClick(e) {
    if (e.target.tagName == 'LI') {
      let check = e.target.getElementsByTagName('INPUT')[0]
      check.checked = !check.checked
      this.filterByOunces()
    }
  }

  filterByOunces() {
    const values = Array
      .from(document.querySelectorAll('input[type="checkbox"]:checked'))
      .map((checkbox) => checkbox.value);
    this.stimulate("filters#ounces", this.defaultCategoryTarget.innerText, values)
  }

  sizeClick(e) {
    if (e.target.tagName == 'LI') {
      let check = e.target.getElementsByTagName('INPUT')[0]
      check.checked = !check.checked
      this.filterBySize()
    }
  }

  filterBySize() {
    const values = Array
      .from(document.querySelectorAll('input[type="checkbox"]:checked'))
      .map((checkbox) => checkbox.value);
    this.stimulate("filters#sizes", this.defaultCategoryTarget.innerText, values)
  }
}
