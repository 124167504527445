import ApplicationController from './application_controller'
import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
import Sortable from "sortablejs"

export default class extends ApplicationController {
  static targets = ["sort"]

  connect() {
    StimulusReflex.register(this)

    Sortable.create(this.sortTarget, {
      onEnd: (event) => this.reorder(event)
    });
  }

  reorder(event) {
    this.stimulate("IdeasReflex#reorder", event.item, event.newIndex);
  }

}
