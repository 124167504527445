import { Controller } from "stimulus"

export default class extends Controller {

  launchBillingForm(event) {
    event.preventDefault()
    $("#edit-billing-address").modal('show')
  }

  launchShippingForm(event) {
    event.preventDefault()
    $("#edit-shipping-address").modal('show')
  }

  addressForm(event) {
    event.preventDefault()
    $("#edit-address").modal('show')
  }

  noteForm(event) {
    event.preventDefault()
    $("#new-note").modal('show')
  }

  pricematchForm(event) {
    event.preventDefault()
    $("#price-match").modal('show')
  }


  addNote(event) {
    event.preventDefault()
    $("#add-note").modal('show')
  }

}
